import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { get } from '../lib/nodash';
import { Box, Heading, Stack } from 'grommet';

import ImgixImage from './ImgixImage';
import { getClientForUrl, getKeyFromUrl } from '../lib/imgix';
import useRichText from './useRichText';
import useMobile from './useMobile';
import useMenuOffset from './useMenuOffset';
import useAdjustedHeadingSize from './useAdjustedHeadingSize';
import { use100vh } from 'react-div-100vh';

const Hero = ({
  title,
  body,
  image,
  height,
  contentAnchor = 'center',
  mobileContainImage = false,
  headingLevel = 3,
  sizeAsHeadingLevel,
  ...rest
}) => {
  const isMobile = useMobile();
  const richText = useRichText(body, {}, rest);
  const imgixClient = getClientForUrl(get('filename', image));
  const buybarHeight = useSelector((state) => state.ui.buybarHeight);
  const adjustedFontStyle = useAdjustedHeadingSize(sizeAsHeadingLevel);
  const { announcementBarSize, navHeight } = useMenuOffset();
  const viewportHeight = use100vh();

  const headingAlign =
    contentAnchor.indexOf('left') > -1
      ? 'start'
      : contentAnchor.indexOf('right') > -1
      ? 'end'
      : 'center';
  const pixelHero = useMemo(
    () =>
      get('filename', image)
        ? imgixClient.buildURL(getKeyFromUrl(get('filename', image)), {
            q: 90,
            maxw: 1500,
            px: 400,
            fm: 'png',
            auto: ['compress'],
          })
        : undefined,
    [image, imgixClient]
  );

  return (
    <Box
      className="hero-container"
      height={`calc(${
        height === '100vh'
          ? viewportHeight
            ? `${viewportHeight}px`
            : height
          : '100vh'
      } - ${navHeight}px - ${isMobile ? 0 : announcementBarSize}px)`}
      {...rest}
    >
      <Box fill align="center" justify="center">
        <Stack
          interactiveChild="last"
          fill
          alignSelf="stretch"
          anchor={contentAnchor}
        >
          <Box fill>
            <ImgixImage
              src={get('filename', image)}
              fit={mobileContainImage ? 'contain' : 'cover'}
              height="100%"
              lazy={false}
              lazySrc={pixelHero}
              options={{ q: 80, w: 800, auto: ['compress', 'format'] }}
              srcSetOptions={{ q: 80, w: 900, auto: ['compress', 'format'] }}
              alt={title}
              a11yTitle={`Photo of ${get('alt', image)}`}
            />
          </Box>

          <Box
            justify="center"
            pad={
              isMobile && contentAnchor.includes('bottom')
                ? { bottom: `${buybarHeight}px`, horizontal: 'large' }
                : 'large'
            }
            fill
            alignSelf="stretch"
          >
            <Heading
              level={headingLevel}
              margin={{ vertical: 'small' }}
              textAlign={headingAlign}
              style={adjustedFontStyle}
            >
              {title}
            </Heading>
            <Box width={{ max: 'large' }}>{richText}</Box>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};

Hero.propTypes = {
  title: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  height: PropTypes.string,
  image: PropTypes.object,
  mobileContainImage: PropTypes.bool,
  headingLevel: PropTypes.oneOf([
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    1,
    2,
    3,
    4,
    5,
    6,
  ]),
  sizeAsHeadingLevel: PropTypes.oneOf([
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    1,
    2,
    3,
    4,
    5,
    6,
  ]),
  contentAnchor: PropTypes.oneOf([
    'center',
    'top',
    'top-right',
    'top-left',
    'right',
    'left',
    'bottom-right',
    'bottom-left',
  ]),
};

export default memo(Hero);
