import React from 'react';
import PropTypes from 'prop-types';
import { storyblokEditable } from 'gatsby-source-storyblok';

import Hero from '../Hero';

const DynamicHero = ({ blok }) => (
  <Hero {...storyblokEditable(blok)} {...blok} />
);

DynamicHero.propTypes = {
  blok: PropTypes.object.isRequired,
};

export default DynamicHero;
